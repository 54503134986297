.filter-by-group-pane {
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.08);
  margin-top: 74px;
  padding: 0;
}

.filter-by-group-panel-header {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 14px;
    border-bottom: 1px solid #e5e7ea;
  /*background-color: var(--mp-new-footer-color);*/
  margin-bottom: 20px;
  font-weight: 700;
}

.tree-chevron a i {
  color: var(--mp-icon-color);
  width: 12px;
  height: 16px;
  cursor: pointer;
}

.tree-chevron {
  margin:0px 8px;
}

.tree-chevron-list {
  padding-right: 30px;
}

.tree-chevron-list a, .group-id a {
  font-size: 14px;
  color: var(--mp-sideNavText-color);
  font-weight: 400;
  text-decoration: none;
}

.tree-chevron-list a i {
  color: var(--mp-icon-color);
  margin-right: 10px;
}

.group-tiles {
  padding: 5px 0;
  display: flex;
}

.item-count {
  right: 0px;
  color: var(--mp-entity-item-count);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  background-color: var(--mp-entity-item-count-background);
  border-radius: 20px;
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.pr-5 {
  padding-right: 5px;
}

.thin-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: var(--mp-light-gray);
}

.thin-scroll::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  background-color: var(--mp-light-gray);
}

.thin-scroll::-webkit-scrollbar-thumb {
  background-color: var(--mp-scroll-background-color);
  border: 2px solid var(--mp-scroll-border-color);
  border-radius: 2px 2px 2px 2px;
}

.no-chevron {
  padding: 0px 0px 0px 16px;
  word-break: break-word;
  width: calc(100% - 40px);
}

.rotate {
  -moz-transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.rotate.down {
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.asset-group-tree {
  overflow: auto;
  height: calc(100vh - 180px);
}

.rotate-pin-filter-by-group-icon {
  font-size: medium;
  margin-right: 20px;
  color: var(--mp-icon-color);
}

.pull-right, .pull-right:hover {
  text-decoration: none;
}

/* switch warehouse slide specific css */

.switch-warehouse-slider {
  font-size: medium;
  margin-right: 20px;
  margin-left: 10px;
  color: var(--mp-icon-color)
}

.warehouse-switcher-entity {
  list-style-type: none;
  display: none;
}

.active-list-item {
  background-color: var(--mp-list-bg-white);
  color: var(--mp-sideNavText-color);
  text-decoration: none;
  font-weight: 600;
}

.warehouse-switcher-warehouse:hover {
  background-color: var(--mp-list-bg-hover-white);
  color: var(--mp-sideNavText-color);
  text-decoration: none;
}

.warehouse-switcher-warehouse:disabled {
  background-color: var(--mp-list-bg-disabled-white);
  color: var(--mp-disabled-button-text-color);
  text-decoration: none;
}

.warehouse-switcher-warehouse {
  background: var(--mp-light-gray);
  padding-left: 10px;
  text-decoration: none;
}

.warehouse-switcher-warehouse > li {
  color: var(--mp-sideNavText-color);
  background: var(--mp-light-gray);
}

.warehouse-switcher-warehouse > a {
  text-decoration: none;
  color: var(--mp-sideNavText-color);
}

.warehouse-switcher-warehouse > a:focus {
  text-decoration: none;
}

.warehouse-switcher-warehouse > a:hover {
  text-decoration: none;
}

.warehouse-switcher-warehouse > a:disabled {
  text-decoration: none;
}
